import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import InputMask from "react-input-mask";

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay,TimeDisplayHHmm,DateDisplayFullNameMonth  } from '../../utils/common'
import { ApiExternalGetEventInfo,ApiExternalCheckIn } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import iconPlus from '../../assets/images/icon-plus.svg'
import logoIcon from '../../assets/images/logo-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { ImUserCheck } from "react-icons/im";
import checkin_footer from "../../assets/images/checkin_footer.png";


const Div = styled.div`
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    .css_magin_top_12 {
        margin-top: 12px;
    }
    .css_magin_top_32 {
        margin-top: 32px;
    }
    .img-container {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        
        img {
            width: 150px;
            /*height: 48px;*/
        }
    
    }
    .title{
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      color : #101828;
      text-align: center;
    }
    .desc{
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color : #475467;
      text-align: center;
    }
    .event_name{
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      color : #182230;
    }
    .event_date{
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #A91F23;
    }
    .event_date_time{
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #182230;
    }
    .event_organizations_name{
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color : #182230;
    }
    .button-login {
        
        width: 100%;
    }
    .box_button{
      display: flex;
      gap: 10px;
      justify-content: center;
    }
    .box_icon{
      display: flex;
      align-items: center;
    }
    .footer_img{
      img {
        width: 390px;
      }
    }
    @media screen and (max-width: 440px) {
      max-width: 300px;
      .box_display_mobile{
        display: none;
      }
      .footer_img{
        img {
          width: 100%;
        }
      }
    }
    .button_disable{
      width: 100%;
      color: #FFFFFF;
      background-color: #344054;
      border: 1px solid #344054;
    }


`

const DivStyleInputMask = styled.div`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;
  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }
`



const EventCheckIn = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { id } = useParams()
  const navigate = useNavigate()

  //---- กรณีเป็น URL Production เก่าจะสั่ง redirect ไปที่ URL Production ใหม่
  useEffect(() => {
    if(window.location.hostname == 'cpdmember.coe.or.th'){
      window.location.href = 'https://cpdmemberv2.coe.or.th/EventCheckIn/' + id
    }
  }, [])

  const initMessageModal = {
    headline: '',
    subtitle: '',
    description: '',
}
const [successModal, setSuccessModal] = useState(false)
const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
const [failModal, setFailModal] = useState(false)
const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [DataInfo, setDataInfo] = useState({
    event_name : '',
    start_at : '',
    organizations_name : '',
    is_open_general_user : false,
    start_at_now : '',
    time_start_at : '',
    check_in_time_start : '',
    check_in_time_end : '',
    button_disable_checkin : '',
    is_member : false,
  })
  const [UserName, setUserName] = useState('')
  
  useEffect(() => {
    const sent_data_to_api = {
        event_id : id,
    };
    dispatch(ApiExternalGetEventInfo(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.data_info){
                setDataInfo({
                  event_name : data_info.data_info.name,
                  start_at : data_info.data_info.start_at,
                  organizations_name : data_info.data_info.organizations_name,
                  is_open_general_user : data_info.data_info.is_open_general_user,
                  start_at_now : data_info.data_info.start_at_now,
                  time_start_at : data_info.data_info.time_start_at,
                  check_in_time_start : data_info.data_info.check_in_time_start,
                  check_in_time_end : data_info.data_info.check_in_time_end,
                  button_disable_checkin : data_info.data_info.button_disable_checkin,
                  is_member : data_info.data_info.is_member,
                })
            }
        }
    })
  }, [dispatch])
  
  

  const ApiProcess = () => {
    const real_UserName = UserName.replaceAll('-', '');
    localStorage.setItem('real_UserName', real_UserName);
    const data_to_api = {
        event_id : id,
        user_name : real_UserName
    };
    dispatch(ApiExternalCheckIn(data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.code == 200){
            setSuccessMessageModal({
                headline: 'Check-in สำเร็จ',
                subtitle: data_info.full_name,
            })
            setSuccessModal(true);
          }else if(data_info.code == 202){
            const description = 'กรณีสมาชิกภาพของท่านหมดอายุ จึงไม่สามารถ Check-in ได้ ขอความกรุณาท่านโปรดตรวจสอบและต่ออายุสมาชิกภาพ เพื่อประโยชน์ในการ Check-in ' + data_info.topic_name + ' ต่อไป'
            setFailMessageModal({
              headline: 'ขออภัยไม่สามารถ Check-in ได้',
              description: description,
            })
            setFailModal(true)
          }else{
            if(data_info.message == 'ท่านไม่ได้เป็นสมาชิกสภาวิศวกร' && DataInfo?.is_open_general_user){
              if(DataInfo?.is_member){
                setFailMessageModal({
                  headline: 'กรุณาตรวจสอบเลขประจำตัวประชาชน',
                  subtitle : 'ขออภัย ระบบไม่พบข้อมูลสมาชิกสภาวิศวกรของท่าน',
                  description: 'กรุณาตรวจสอบและแก้ไขข้อมูลของท่านอีกครั้ง',
                })
                setFailModal(true)
              }else{
                navigate('/EventCheckInGeneral/' + id)
              }
              
            }else{
              setFailMessageModal({
                headline: 'ขออภัยไม่สามารถ Check-in ได้',
                description: data_info.message,
              })
              setFailModal(true)
            }



          }
        }else{
          setFailMessageModal({
            headline: 'internal server error',
            description: '',
          })
          setFailModal(true)
        }
    })
  }
  
  const gotoCheckInGeneral = () => {
    navigate('/EventCheckInGeneral/' + id)
  }

  return (
    <Div >
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">ลงชื่อเข้าร่วมกิจกรรม CPD</div>
        <div className="css_magin_top_32"></div>
        <div className="event_name">{DataInfo.event_name}</div>
        <div className="css_magin_top_12"></div>
        <div className="event_organizations_name">
          โดย {DataInfo.organizations_name}
        </div>
        <div className="css_magin_top_12"></div>
        <div className="event_date">วันที่ {DateDisplayFullNameMonth(DataInfo.start_at_now)}</div>
        <div className="event_date">เวลาเริ่มกิจกรรม {DataInfo.time_start_at} น.</div>
        <div className="css_magin_top_12"></div>
        <div className="event_date_time">ลงชื่อได้ระหว่าง {DataInfo.check_in_time_start} - {DataInfo.check_in_time_end} น.</div>
        <div className="css_magin_top_32"></div>
        <div>
            <Field className="field" label="เลขบัตรประจำตัวประชาชน">
                <DivStyleInputMask>
                <InputMask 
                    placeholder="ระบุเลขบัตรประจำตัวประชาชน 13 หลัก"
                    mask="9-9999-99999-99-9" 
                    onChange={(e) => setUserName(e.target.value)} 
                    value={UserName} 
                />
                </DivStyleInputMask>
            </Field>
        </div>
        <div className="css_magin_top_32"></div>


          {DataInfo.button_disable_checkin != '-' && (
            <Button
              className="button_disable"
              type="submit"
              onClick={console.log('-')}
            >
              <div className="box_button">
                <div className="box_icon"><ImUserCheck /></div>
                <div>{DataInfo.button_disable_checkin}</div>
              </div>
            </Button>
          )}
          {DataInfo.button_disable_checkin == '-' && (
            <Button
              className="button-login"
              type="submit"
              onClick={ApiProcess}
              disabled={!UserName}
            >
              <div className="box_button">
                <div className="box_icon"><ImUserCheck /></div>
                <div>ลงชื่อเข้าร่วมกิจกรรม</div>
              </div>
              
            </Button>
            )
          }


          <div className="css_magin_top_32"></div>
          <div className="desc">
              ลงชื่อเพื่อรับคะแนน CPD ทันทีเมื่อจบกิจกรรม
          </div>
          <div className="css_magin_top_12"></div>
          <div className="desc">
            หมายเหตุ : หากไม่สามารถลงชื่อได้ทันตามกำหนด ท่านสามารถรับ คะแนน CPD ได้ที่หน้าบันทึกคะแนน (Self Declaration)
          </div>
          <div className="footer_img"><img src={checkin_footer} alt="loco icon" /></div>

          <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => setSuccessModal(false)}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            nameItem={failMessageModal.subtitle}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default EventCheckIn
