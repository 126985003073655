import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import InputMask from "react-input-mask";

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay,TimeDisplayHHmm,DateDisplayFullNameMonth  } from '../../utils/common'
import { ApiExternalEventCheckEmail,ApiExternalEventSentEmail } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import iconPlus from '../../assets/images/icon-plus.svg'
import logoIcon from '../../assets/images/logo-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    .css_magin_top_12 {
        margin-top: 12px;
    }
    .css_magin_top_32 {
        margin-top: 32px;
    }
    .img-container {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        
        img {
            width: 150px;
            /*height: 48px;*/
        }
    
    }
    .title{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color : #101828;
        text-align: center;
    }
    .desc{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color : #475467;
        text-align: center;
    }
    .event_name{
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #182230;
    }
    .event_date{
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color : #182230;
    }
    .event_organizations_name{
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color : #182230;
    }
    .button-login {
        display: block;
        width: 100%;
    }
    .event_email{
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        color : #A91F23;
        margin: 12px 0px;
    }

    @media screen and (max-width: 440px) {
      max-width: 300px;
    }

`

const DivStyleInputMask = styled.div`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;
  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }
`



const EventSentEmail = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { id,user_name,user_organization_name } = useParams()
  const navigate = useNavigate()

  const initMessageModal = {
    headline: '',
    subtitle: '',
    description: '',
}
const [successModal, setSuccessModal] = useState(false)
const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
const [failModal, setFailModal] = useState(false)
const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [DataInfo, setDataInfo] = useState({
    event_name : '',
    start_at : '',
    organizations_name : '',
    email : '',
  })
  const [UserName, setUserName] = useState('')
  
  useEffect(() => {
    const sent_data_to_api = {
        event_id : id,
        user_name : user_name,
    };
    dispatch(ApiExternalEventCheckEmail(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info){
                setDataInfo({
                    event_name : data_info.events_name,
                    start_at : data_info.start_at,
                    organizations_name : data_info.organizations_name,
                    email : data_info.email
                })
            }
        }
    })
  }, [dispatch])
  
  

  const ApiProcess = () => {
    const data_to_api = {
      event_id : id,
      user_name : user_name,
      user_organization_name : user_organization_name == '-internal' ? '-' : user_organization_name
    };
    dispatch(ApiExternalEventSentEmail(data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.code == 200){
            navigate('/EventPreregisterEmail/' + id + '/' + DataInfo.email)
            /*
            setSuccessMessageModal({
                headline: 'กรุณาตรวจสอบ Email',
                subtitle: DataInfo.email,
                description: 'และกดยืนยันเพื่อรับสิทธิ์',
            })
            setSuccessModal(true);
            */
          }else{
            setFailMessageModal({
              headline: 'ขออภัยไม่สามารถส่ง Email ได้',
              description: data_info.message,
            })
            setFailModal(true)
          }
        }else{
          setFailMessageModal({
            headline: 'internal server error',
            description: '',
          })
          setFailModal(true)
        }
    })
  }

  const gotoPreregisterGeneral = () => {
    navigate('/EventPreregisterGeneral/' + id)
  }

  const backToHome = () => {
    if(user_organization_name == '-internal'){
      navigate('/Activity/Events')
    }else{
      setSuccessModal(false)
    }

 }
  
  return (
    <Div>
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">ลงทะเบียนเข้าร่วมกิจกรรมองค์กรแม่ข่าย</div>
        <div className="css_magin_top_32"></div>
        <div className="event_name">{DataInfo.event_name}</div>
        <div className="css_magin_top_12"></div>
        <div className="event_date">วันที่ {DateDisplayFullNameMonth(DataInfo.start_at)} เวลา {TimeDisplayHHmm(DataInfo.start_at)} น.</div>
        <div className="css_magin_top_12"></div>
        <div className="event_organizations_name">
          โดย {DataInfo.organizations_name}
        </div>
        <div className="css_magin_top_32"></div>
        <div className="event_name">
            กรุณาตรวจสอบ Email ของท่าน
        </div>
        <div className="event_email">
            {DataInfo.email}
        </div>
        <div className="desc">
            เมื่อกดยืนยันแล้วระบบจะจัดส่งข้อความยืนยันการลงทะเบียน
        </div>
        <div className="desc">
            รับคะแนน CPD ไปยัง Email ที่แสดงนี้ 
        </div>
        <div className="desc">
            กรุณากดยืนยันเพื่อรับสิทธิ์ผ่าน Email
        </div>
        <div className="css_magin_top_32"></div>
        <Button
            className="button-login"
            type="submit"
            onClick={ApiProcess}
            disabled={!DataInfo.email}
          >
            ยืนยัน
          </Button>
          <div className="css_magin_top_32"></div>
          <div className="desc">
            หากต้องการเปลี่ยนแปลง Email 
          </div>
          <div className="desc">
            กรุณาแก้ไขข้อมูลที่เมนูข้อมูลส่วนบุคคล
          </div>

          <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={backToHome}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default EventSentEmail
