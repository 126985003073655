import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_MEMBER,
    POST_MEMBER,
    UPDATE_MEMBER
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const ApiMemberMyCpd = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_MEMBER.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/member/MemberMyCpd`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiMemberMyCpd_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiMemberMyCpd_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiMemberApproveConsent = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_MEMBER.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberApproveConsent`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberApproveConsent_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberApproveConsent_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetVWUserHistoryPoint = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_MEMBER.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.activity_name){
      params = {...params,'activity_name[$like]': `%${filter.activity_name}%`}
    }

    if(filter.ref_activity){
      params = {...params,'ref_activity': filter.ref_activity}
    }



    if(filter.effective_date){
      params = {...params,'effective_date': filter.effective_date}
    }

    const data_result = await getAPI({
      url: '/api/member/vw_user_point_history?$sort[effective_date]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetVWUserHistoryPoint_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetVWUserHistoryPoint_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberEventProfile = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_MEMBER.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberEventProfile`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberEventProfile_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberEventProfile_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}


export const ApiMemberMyCpdChart = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_MEMBER.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberMyCpdChart`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberMyCpdChart_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberMyCpdChart_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberCheckPointByService = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_MEMBER.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberCheckPointByService`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberCheckPointByService_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberCheckPointByService_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetVWUserHistoryPointLog = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_MEMBER.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }


    if(filter.user_activity_point_id){
      params = {...params,'user_activity_point_id': filter.user_activity_point_id}
    }

    const data_result = await getAPI({
      url: '/api/member/vw_user_point_history_log?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetVWUserHistoryPointLog_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetVWUserHistoryPointLog_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}